import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass({ 'big': $props.isBig }),
    href: "/"
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("p", null, [
      _createTextVNode(" ComputerInfor "),
      _createElementVNode("br"),
      _createElementVNode("small", null, " Well informed ")
    ], -1)
  ]), 2))
}