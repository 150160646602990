import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", null, _cache[0] || (_cache[0] = [
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "Address"),
      _createElementVNode("td", null, "Hoogaars 80")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "Postal Code"),
      _createElementVNode("td", null, "3231 XN")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "City"),
      _createElementVNode("td", null, "Brielle")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "Country"),
      _createElementVNode("td", null, "Netherlands")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "Telephone Number"),
      _createElementVNode("td", null, "+31 6 3900 8814")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "Email Address"),
      _createElementVNode("td", null, "info@computerinfor.nl")
    ], -1),
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, "Website"),
      _createElementVNode("td", null, "www.computerinfor.com")
    ], -1)
  ])))
}