import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "introduction" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_layout = _resolveComponent("header-layout")!
  const _component_banner_layout = _resolveComponent("banner-layout")!
  const _component_double_block = _resolveComponent("double-block")!
  const _component_footer_layout = _resolveComponent("footer-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_layout, { displayBreadcrumb: false }),
    _createVNode(_component_banner_layout, {
      slogan: "This domain is managed by ComputerInfor",
      subtitle: "ComputerInfor, well informed"
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_double_block, null, {
          default: _withCtx(() => [
            _createElementVNode("img", { src: $data.experienceImage }, null, 8, _hoisted_3),
            _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Experience", -1)),
            _cache[1] || (_cache[1] = _createElementVNode("p", null, " ComputerInfor has experience with developing and deploying front-end and back-end software. For instance, this tiny web application is built with Vue and deployed on Kubernetes! ", -1))
          ]),
          _: 1
        }),
        _createVNode(_component_double_block, null, {
          default: _withCtx(() => [
            _createElementVNode("img", { src: $data.goalImage }, null, 8, _hoisted_4),
            _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Goal", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("p", null, " ComputerInfor strives for honest communication, clear agreements and complete customer satisfaction. Let yourself be convinced and make an appointment! ", -1))
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_footer_layout)
  ], 64))
}